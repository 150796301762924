<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import RoomieFooter from '@lahaus-roomie/roomie/src/components/RoomieFooter/index.vue'
import { storeToRefs } from 'pinia'

import countries from '@/config/countries.json'
import socialNetworks from '@/config/socialNetworks.json'
import { SCREEN } from '@/utils/segment'
import { useLocationsStore } from '@/stores/locationsStore'
import { useUserStore } from '@/stores/userStore'
import { useContactFormStore } from '@/stores/contactFormStore'
import useListingsSearch from '@/composables/useListingsSearch'
import useSearchResultsMetadata from '@/composables/useSearchResultsMetadata'
import type { InvestmentProjectSummary } from '@/server/api/buyer-front/types'
import AppStaticHeader from '@/components/App/StaticHeader/index.vue'
import LockOverlay from '@/components/LockOverlay/index.vue'
import OnboardingFiltersBar from '@/components/OnboardingContent/FiltersBar/index.vue'
import SearchResultsContentHeader from '@/components/SearchResultsContent/Header/index.vue'
import OnboardingEmptyResultsBanner from '@/components/OnboardingContent/EmptyResultsBanner/index.vue'
import SearchResultsContentHero from '@/components/SearchResultsContent/Hero/index.vue'
import OnboardingResultsCarousels from '@/components/OnboardingContent/ResultsCarousels/index.vue'
import { parseMetadata } from '@/composables/useSearchResultsMetadata/utils'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import './_index.scss'

const { t, locale } = useI18n()
const route = useRoute()
const locationsStore = useLocationsStore()
const userStore = useUserStore()
const contactFormStore = useContactFormStore()
const searchFiltersStore = useSearchFiltersStore()
const { currentFilters } = storeToRefs(searchFiltersStore)

const currentBusinessHubsCode = computed(() => {
  const businessHubs = locationsStore.locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(currentFilters.value.neighborhoods)

  return businessHubs.map(({ code }) => code)[0]
})

await callOnce(async () => {
  if (locationsStore.areLocationsLoaded) return

  await locationsStore.initializeLocations()
})

const { getMetadata } = useSearchResultsMetadata()

const { data: metadataData } = await useAsyncData<SeoMetadata>(`seometadata/${route.path}`, async () => {
  return await getMetadata()
})

const { baseMetadata = {}, customSrpOptions = {} } = metadataData.value || {}

const getCurrentCountry = () => {
  return locale.value === 'co' ? countries[1] : countries[0]
}

const { search, isLoading, results, emitListingsSearchedEvent } = useListingsSearch({
  screen: SCREEN.INVESTMENT_SIMULATOR,
  ...customSrpOptions
})

const { data: initialResults } = await useAsyncData<SearchResults>('listings-search', async () => {
  return await search()
})

results.value = initialResults.value

const totalResultsCount = computed(() => {
  if (!results.value) return 0

  const exactMatchResultsCount = results.value.exactMatchProjects.length
  const extendedSearchResultsCount = results.value.extendedSearch.map(result => result.projects).flat().length
  const extraResultsCount = results.value.extraResults.map(result => result.projects).flat().length

  return exactMatchResultsCount + extendedSearchResultsCount + extraResultsCount
})

const metadata = computed(() => {
  return parseMetadata(
    {
      metadata: baseMetadata || {},
      resultsCount: totalResultsCount.value
    },
    { t, locale: locale.value }
  )
})

useSeoMeta(metadata.value)
useHead({ link: [{ rel: 'canonical', href: metadata.value?.canonicalUrl || route.path }] })

const { y } = useWindowScroll()

const outstandingProject = computed(() => results.value?.outstandingProject?.code ? results.value.outstandingProject : null)

const exactMatchResultsIds = computed(() => [outstandingProject.value?.code, ...results.value.exactMatchProjects.map(project => project.code)].filter(Boolean))

const isHeroVisible = computed(() => !!outstandingProject.value?.code)

const canShowFloatingFiltersBar = computed(() => y.value > 170)

const isContentLocked = computed(() => !userStore.isAuthenticated && !contactFormStore.userEmail)

onMounted(() => {
  emitListingsSearchedEvent({ userAction: 'DEFAULT_FILTERS' })
})
</script>

<script lang="ts">
export default {
  name: 'SearchResultsContent'
}
</script>

<template>
  <div class="search-results-page">
    <AppStaticHeader class="w-full max-w-[1800px] mx-auto" />

    <Transition name="slide-down">
      <div
        v-show="canShowFloatingFiltersBar"
        class="fixed w-full left-0 top-0 bg-white z-50">
        <OnboardingFiltersBar
          :placeholder="t('emptyFiltersPlaceholder')"
          :screen="SCREEN.SEARCH_RESULTS"
          class="max-w-[1800px] md:px-40 py-8 mx-auto md:mb-16 mt-0 md:mt-12 pr-24 md:pr-36" />
      </div>
    </transition>

    <SearchResultsContentHeader
      :title="metadata.titleH1"
      :enable-title="!!outstandingProject"
      class="left-0 top-0 bg-white max-w-[1800px] md:px-40 py-12 mx-auto md:mb-16 z-20 mt-0 md:mt-12 pr-24 md:pr-36" />

    <div
      v-if="isHeroVisible"
      class="max-w-[1800px] mx-auto px-0 md:px-40"
      :class="{ 'mb-40': !isContentLocked, 'md:mb-24': isContentLocked }">
      <SearchResultsContentHero
        :screen="SCREEN.SEARCH_RESULTS"
        class="onboarding-v2__hero"
        :class="{ 'blur-sm': isLoading }"
        :listing="outstandingProject as InvestmentProjectSummary"
        :hide-title="isContentLocked" />
    </div>

    <OnboardingEmptyResultsBanner
      v-if="!outstandingProject"
      :screen="SCREEN.SEARCH_RESULTS"
      class="search-results-page__empty-results-banner max-w-[1800px] mx-auto" />

    <div class="relative">
      <LockOverlay
        v-if="isContentLocked"
        :screen="SCREEN.SEARCH_RESULTS"
        :listing-ids="exactMatchResultsIds.slice(0,3)"
        :hub-code="currentBusinessHubsCode"
        show-general-title
        class="z-40 absolute h-full" />

      <OnboardingResultsCarousels
        v-if="results"
        class="max-w-[1800px] mx-auto"
        :class="{ 'blur-sm': isLoading, 'mb-40': !isContentLocked, 'pt-24 mb-0': isContentLocked }"
        :exact-match-projects="results.exactMatchProjects"
        :extended-search="results.extendedSearch"
        :extra-results="results.extraResults"
        :screen="SCREEN.SEARCH_RESULTS"
        :carousels-limit="isContentLocked ? 1 : null"
        :is-loading />
    </div>

    <RoomieFooter
      class="roomie-footer"
      :countries="[getCurrentCountry()]"
      :social-networks="socialNetworks"
      :applications-links="[]" />
  </div>
</template>

<i18n src="./i18n.json" />

<style lang="scss">
  .roomie-footer {
    z-index: 0;

    &__navigation-title {
      @apply font-bold;
    }
  }
</style>
